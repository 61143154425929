import React from "react";

import SEO from "src/components/Seo";
import Default from 'src/pages/_layouts/Default';
import BlogTitle from 'src/components/BlogTitle';
import Content from 'src/components/Content';

import Blog from 'src/components/Blog';

export default function BlogPage() {
  return (
    <Default >
      <SEO title="Blog" />
      <BlogTitle title="Blog" />
      <Content>
        <Blog />
      </Content>
    </Default >
  );
}