import React from 'react';

import posed, { PoseGroup } from 'react-pose'

import { Container } from './styles';


import BackgroundInterno from 'src/components/Images/BackgroundInterno';
import Cloud from 'src/components/Animations/Cloud';
import nuvem_1 from 'src/images/nuvem_1.png';
import nuvem_2 from 'src/images/nuvem_2.png';
import nuvem_3 from 'src/images/nuvem_3.png';

export default function MainTitle({ title }) {
  const Box = posed.div({
    left: { x: -100 },
    right: { x: 100 }
  })

  return (
    <Container>
      <Box />
      <Cloud src={nuvem_1} className="cloud cloud_1" />
      <Cloud src={nuvem_2} className="cloud cloud_2" />
      <Cloud src={nuvem_3} className="cloud cloud_3" />
      <h1>Blog</h1>
      <BackgroundInterno />

    </Container>
  );
}
